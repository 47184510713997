/* Global styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal overflow */
}

.layout {
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  margin:0 auto;
 // min-height:100vh;
  width:100%;
  //max-width: 1440px;
  justify-content: space-between;
}

.layout > * {
  box-sizing: border-box; /* Apply box-sizing to child elements */
}

.redeem{
  background: var(--UPS-Brand-Colors-UPS-Grey-5, #F2F1EF);
  width: 100%;
}

.content{
  flex:1; // grow to fill the space between header and footer
  padding:1 rem;
}


.page-container {
    display: flex;
   // width: 1440px;
    margin: 0 auto;
  }
  
  
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
//    justify-content: center;
   // align-items: center;
 //  margin-top:30px; 

 .headline-highlight {
    margin-top:20px;
    margin-bottom:40px;
 }

  }
  
  .content-box {
    display:flex;
    background-color: #fff;
    margin:0 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 728px;
  }