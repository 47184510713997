
#dw-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; // Center horizontally
  }

#dw-container .dw-header{
   
    height:112px;
    background: var(--UPS-Brand-Colors-UPS-White, #FFF);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

$DigitalWalletApp-primary: (
  50: #e0f2f0,
  100: #b3e1da,
  200: #80cdc1,
  300: #4dbaaa,
  400: #27aa98,
  500: #3fa29d, // Your desired primary color
  600: #389a91,
  700: #2f8a82,
  800: #277573,
  900: #1a5a5d,
  A100: #a7ffeb,
  A200: #74ffd8,
  A400: #41ffbf,
  A700: #23ffb0,
  contrast: (
    50: #000000, // Black with 87% opacity
    100: #000000, // Black with 87% opacity
    200: #000000, // Black with 87% opacity
    300: #000000, // Black with 87% opacity
    400: #000000, // Black with 87% opacity
    500: #ffffff, // White
    600: #ffffff, // White
    700: #ffffff, // White with 87% opacity
    800: #ffffff, // White with 87% opacity
    900: #ffffff, // White with 87% opacity
    A100: #000000, // Black with 87% opacity
    A200: #ffffff, // White
    A400: #ffffff, // White
    A700: #ffffff, // White
  )
);

  