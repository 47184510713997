// === UPS Colors ================================================
// light: 60%, lighter: 40%

// Primary
$ups-gold: #ffb500; // rgba(255, 181, 0, 1)

$ups-brown: #330000; // #351c15; // rgba(53, 28, 21, 1)
$ups-brown-dark: #1b0e0b; // rgba(27, 14, 11, 1)
$ups-brown-light: #573616; // rgba(87, 54, 22, 1)
$ups-brown-lighter: #795a40; // rgba(121, 90, 64, 1)

// Secondary
$ups-grass: #64a70b; // rgba(100, 167, 11, 1)
$ups-grass-dark: #357715; // rgba(53, 119, 21, 1)
$ups-grass-light: #a2ca6d; // rgba(162, 202, 109, 1)

$ups-water: #40c1ac; // rgba(64, 193, 172, 1)
$ups-water-dark: #008369; // rgba(0, 131, 105, 1)
$ups-water-light: #8cdacd; // rgba(140, 218, 205, 1)

$ups-sky: #009cbd; // rgba(0, 156, 189, 1)
$ups-sky-dark: #187ca2; // rgba(24, 124, 162, 1)
$ups-sky-light: #66c4d7; // rgba(102, 196, 215, 1)

$ups-sun: #f68b20; // rgba(246, 139, 32, 1)
$ups-sun-dark: #e45321; // rgba(228, 84, 33, 1)
$ups-sun-light: #f4b966; // rgba(244, 185, 102, 1)
$ups-yellow: #FBD300;

$ups-sand: #e1c8b0; // rgba(225, 200, 176, 1)
$ups-sand-dark: #c3a282; // rgba(195, 162, 130, 1)
$ups-sand-light: #edded0; // rgba(237, 222, 208, 1)
$ups-sand-lighter: #f3e9df; // rgba(243, 233, 223, 1)

$ups-stone: #d9d9d6; // rgba(217, 217, 214, 1)
$ups-stone-dark: #bebebe; // rgba(190, 190, 190, 1)
$ups-stone-light: #e8e8e6; // rgba(232, 232, 230, 1)
$ups-stone-lighter: #f0f0ef; // rgba(240, 240, 230, 1)

// Others
$ups-black-4: #242424; // rgba(36, 36, 36, 1)
$ups-black-3: #404040; // rgba(64, 64, 64, 1)
$ups-black-1: #6e6e6e; // rgba(110, 110, 110, 1)
$ups-black: #121212;
$ups-gray: #AFB1BF;
$ups-gray-border: #D9D9D9;
$ups-gray-background: #4A4A4C;

$ups-gray-dark: $ups-black-4;
$ups-gray-light: $ups-black-3;
$ups-gray-lighter: $ups-black-1;
$ups-gray-1: #5F5753;
$ups-gray-2: #8C857E;
$ups-gray-4: #DFDBD7;
$ups-gray-5: #F2F1EF;

$ups-bray: #595555; // rgba(89, 85, 85, 1);
$ups-bray-light: #cac4bd;
$ups-bray-lighter: #d5cfc9;
$ups-bray-lightest: #dfdbd7;
$ups-background-color: #f4f4f4;

$ups-green: #00857d;
$ups-green-success: #29A855;
$ups-success-green-light: #D4EEDD;
$ups-success-green-deep: #10602C;
$ups-blue: #009cbd;
$ups-blue-alternate: #0662BB;
$ups-blue-hover: #e6eff8;
$ups-teal: #0A8080;
$ups-teal-light: #F8FBFB;
$ups-teal-medium: #C2E3E2;
$ups-feature-teal-light: #E7F2F2;

$ups-error: #c91a13; // rgba(201, 26, 19, 1)
$ups-error-red-deep: #991C00;
$ups-warning-red: #d50000;
$ups-warning-red-light: #FCEAE6;
$ups-warning-red-deep: #991C00;
$ups-warning-yellow-light: #FEF6CC;
$ups-warning-yellow-deep: #615100;

// ===============================================================

$primary: $ups-green;
$primary-light: #4db5ac;
$primary-dark: #00645e; // #005751;
$accent: $ups-green;
$secondary: $ups-blue;
$secondary-light: #5bcdf0;
$secondary-dark: #007992; // #006d8d;

$color-text-default: #242424;
$color-text-secondary: #817b7b; // #8c8585;
$color-text-subscript: #707070;
$color-text-header: rgba($color-text-default, .87);
$color-text-placeholder: rgba(0, 0, 0, .58);
$color-text-table-content: #4a4a4a;
$color-text-on-primary: rgba(white, 1);
$color-text-on-primary-light: rgba(black, .64);
$color-text-on-primary-dark: rgba(white, .65);
$color-text-on-secondary: rgba(white, 1);
$color-text-on-secondary-light: rgba(black, .59);
$color-text-on-secondary-dark: rgba(white, .82);

$color-background: #f4f4f4;
$color-background-light: #f7f7f7;
$color-background-dark: #dddbdc;
$color-background-darker: #cecccc;

$color-button-primary: $ups-water;
$color-button-primary-text: $ups-gray-dark;
$color-button-secondary: $ups-stone;
$color-button-secondary-text: $ups-gray-dark;
$color-button-tertiary: $ups-brown;
$color-button-tertiary-text: white;

$color-button-hover: $ups-gold;
$color-button-text-hover: $ups-gray-dark;
$color-button-inactive: $ups-stone-light;
$color-button-text-inactive: $ups-gray-lighter;

$color-link: $ups-sky;
$color-link-hover: $ups-water;
$color-link-visited: $ups-water;

$color-breadcrumb: $ups-gray-light;

$color-dropdown: $ups-stone;
$color-dropdown-text: $ups-gray-dark;
$color-dropdown-hover: $ups-gold;
$color-dropdown-text-hover: $ups-gray-dark;
$color-dropdown-inactive: $ups-stone-light;
$color-dropdown-text-inactive: $ups-gray-lighter;

$color-tab: white;
$color-tab-text: $ups-gray-dark;
$color-tab-hover: $ups-sand-light;
$color-tab-text-hover: $ups-gray-dark;
$color-tab-border: $ups-sand-light;

$color-table-header-text: $color-text-default;
$color-table-header-border: $ups-sand-lighter;
$color-table-row-border: $ups-sand-lighter;
$color-table-row-alternate: $ups-sand-light;

$color-panel-content-header: $ups-sand;
$color-panel-content-header-text: $color-text-default;
$color-panel-content-border: $ups-sand;
$color-panel-information-header: $ups-sky;
$color-panel-information-header-text: white;
$color-panel-information-border: $ups-sky;

$color-checkbox-frame: #cdcdcd;

$color-notification-background: #fff59d;
$color-notification-background-alert: #d50000;

$color-divider: #979797;

$border-light: #e0e0e0;
$background-color-panel: #fff;
//$text-default: rgba(33, 0, 0, 255);
$text-default: $color-text-default;
$overlay: rgba(20, 19, 0, .9);
$box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
