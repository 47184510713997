$text-default: #242424;
$alpha: grey;
$primary: #c6538c;
$secondary: blue;
$color-text-on-secondary: lightblue;
$secondary-dark: purple;

$ups-black: #121212;
$ups-bray-lightest: grey;
$ups-bray-light: lightgrey;

$font-family-ups: "Roboto", sans-serif;

.button-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-size:16px;
  font-style:normal;
  font-weight:500;
  line-height: 24px;
}

.button-container{
  .btn {
    border:none;
    
    button{
      height:38px;
      border-radius:22px;
    }
  }
}

.btn {
  --bs-btn-focus-box-shadow: none;
  --bs-btn-focus-border-color: transparent;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none !important;
    border-color: transparent !important;
  }

  &:focus-visible {
    outline: none;
    box-shadow: none !important;
    border-color: transparent !important;
  }
}

.mdc-radio__outer-circle {
  border-color: #3fa29d; // Replace #your-desired-color with the hex code of your preferred color
}

.mdc-radio__background::before{
  background-color: #3fa29d;
}

.mdc-radio__background, .mdc-radio__background::before {
  .mdc-radio__outer-circle,
  .mdc-radio__inner-circle {
    border-color: white;
  }
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle{
 border-color:#fff !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle{
  border-color:#00857D !important;
  border-width: 5px !important;
  background-color: #00857D !important;
}

.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
  width: 10px !important; /* Adjust the inner circle size as needed */
  height: 10px !important;
}

.mat-mdc-raised-button:not(:disabled).primary{
  background: var(--ups-brand-colors-ups-gold, #ffc400);
  color:#1b0e0b;
  border-radius: 22px;
  border-color: var(--ups-brand-colors-ups-gold, #ffc400);
}

.mat-mdc-raised-button:not(:disabled).secondary{
  background: transparent;
  border:2px solid var(--UPS-Brand-Colors-UPS-Blue, #0662BB);
  color:  #0662BB;
  border-radius: 22px;
}

.mat-mdc-raised-button:not(:disabled).primary:focus,
.mat-mdc-raised-button:not(:disabled).primary:active,
.mat-mdc-raised-button:not(:disabled):active {
    outline: none !important; /* Remove the default outline */
    box-shadow: none !important; /* Remove any box shadow */
}

::ng-deep .mat-mdc-raised-button:not(:disabled).primary:focus,
::ng-deep .mat-mdc-raised-button:not(:disabled).primary:active {
    outline: none !important; /* Remove the default outline */
    box-shadow: none !important; /* Remove any box shadow */
}

.mat-mdc-raised-button {
  &:not([class*=mat-elevation-z]) {
    border-radius: 4px;
    letter-spacing: .5px;
    font-weight: 700;
    font-size: 1em;
    padding: 0 22px;
    margin:15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 44px;
    text-decoration: none;
  }
}


