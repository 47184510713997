$base-font-size-px: 16;
$base-font-size: #{$base-font-size-px}px;
$font-family-ups: "Roboto", sans-serif;
$font-family-header: "Roboto", sans-serif;
$body-font-family: "Roboto Flex", sans-serif;
$header-font-family: "Roboto Flex", sans-serif;
$header-font-family-alternative: "Open Sans", sans-serif;

$browser-context: $base-font-size-px;


@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return calc($pixels / $context * 1em);
}

@function rem($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return calc($pixels / $context * 1rem);
}


h2{
    color:#333;
    font-family:'Roboto';
    font-size:40px;
    font-style:normal;
    font-weight:400;
    line-height:48px;
}