.padding-left-5{padding-left:5px;}
.padding-left-10{padding-left:10px;}
.padding-left-20{padding-left:20px;}

h2{
    color:#333;
    font-family:'Roboto';
    font-size:40px;
    font-style:normal;
    font-weight:400;
    line-height:48px;
}

h3{
    color: var(--UPS-Brand-Colors-UPS-Black, #121212);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Header/20px Medium */
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    }

h4{
    color: var(--UPS-Brand-Colors-UPS-Black, #121212);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Header/20px Medium */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 140% */
    }


p.patttern-1{
    color: var(--UPS-Brand-Colors-UPS-Grey-2, #8C857E);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Header/16px Medium */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}  

.headline-highlight {
    position: relative;
    display: inline-block;
    font-weight:400;
    font-size:40px;
    margin:20px 0 40px;
    
}

.headline-highlight::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 72px;
    height: 4px;
    background-color:#ffb500 ;
}

.sub-container{
    margin:0 auto; 
    max-width: 1440px; 
}
.payment-sub-container
{
display: flex;
width: 600px;
height: 1451px;
padding: 64px 40px;
flex-direction: column;
align-items: flex-start;
gap: 24px;

border-radius: 4px 0px 0px 4px;
background: #FFF;
}

.flex-center{
    display:flex;
    align-items: center;
}


.box-dw-primary{
    display: flex;
    padding: 24px;
    align-items: center; 
    justify-content: space-between;   
    background: var(--Gradient-Colors-Arch, linear-gradient(351deg, #F2F1EF 8%, rgba(255, 255, 255, 0.00) 55.74%), #FFF);
    /* Shadow/Card/Enabled */
    box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 2px 5px 0px rgba(0, 0, 0, 0.14); 
    margin-bottom: 10px;
    
    .column{
        display:flex;
    }

    .title-1{
            color: var(--UPS-Brand-Colors-UPS-Black, #121212);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 133.333% */
            letter-spacing: 3px;
            top:0;
            text-align: center;
    }
}

.box-border{
    border-radius: 4px;
    background: var(--UPS-Brand-Colors-UPS-White, #FFF);
    /* Shadow/Card/Enabled */
    box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
    padding:22px;
    margin:20px 0;
}

.box-border-inner{
    border-radius: 4px;
    border: 1px solid var(--UPS-Brand-Colors-UPS-Grey-3, #BFB8AF);
    background: var(--UPS-Brand-Colors-UPS-White, #FFF);
    padding:10px;
    margin:10px 0;
}

.box-rounded{
    border-radius: 15px;
    background: var(--UPS-Brand-Colors-UPS-White, #FFF);
    border:1px solid #999;
    padding:10px 20px;
    margin:20px 0;
    width:100%
}

@media (max-width: 600px) {
    .sub-container{
        padding:0 30px; 

    }
}

.mat-mdc-form-field-subscript-wrapper {
    height:0;
  }

  .mdc-text-field{
    margin:0;
    padding:5px 0 !important;
  }

  .mat-mdc-select-value{
    padding-left:10px;
  }

  .mat-mdc-form-field-inflix{
    padding:0px;
  }

  .mdc-checkbox__ripple { 
    display: none !important;
  }

 .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 0px !important;
    padding-bottom:0px !important;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
    background-color:#fff !important;
  }

  .mdc-checkbox__background{
    border-radius: 5px !important;
    height:21px !important;
    width:21px !important;
  }

  .custom-paginator {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  

  // Search Field on Transaction Tables

  .search-box-container {
    display: flex;
    align-items: center;
 
    &.box-border{
      margin: 10px 0;
    padding: 0;
    border-radius: 4px;
    border: 1px solid var(--Brand-UPS-Grey-1, #5F5753);
    background: var(--UPS-Brand-Colors-UPS-Grey-5, #F2F1EF);
    }

  
    .search-container {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      .search-box{
        background-color: #fff;
        padding:11px 20px 8px 47px;
        border:1px solid #666;
        min-width: 320px;;
      }
  
      mat-form-field {
        margin:5px 10px 0 10px;
        display:flex;
        align-items: center;
        width:350px;

      }
    }
  }

  .mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon {
    padding: 5px !important;
    position:fixed;
    z-index: 200;
    top:13px;
    left:16px;
  }

