table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    /* Shadow/Card/Enabled */
    box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    overflow:hidden;
  
    
  
    thead tr th{
       background-color: #5f5753;
       border:2px solid #fff;
       color:white;
       color: var(--Brand-UPS-White, #FFF);
       font-feature-settings: 'clig' off, 'liga' off;
       /* Header/16px Medium */
       font-family: 'Roboto';
       font-size: 16px;
       font-style: normal;
       font-weight: 400;
       line-height: 40px; /* 150% */
    }

    th, td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ccc;
      line-height: 24px;
    }
  
    th {
      background-color: #f0f0f0;
      font-weight: bold;
    }
  
    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  
    input[type="checkbox"] {
      justify-content: center;
    }

    input.wide-check-box{
      // width:30px;
      // height:30px;
      transform: scale(2);
      border-radius: 5px;
      margin-left:10px;
    }

    tr th span.dw-icon{
      float:right;
      align-items: center;
  }
  }

.mat-elevation-z8{
  margin-bottom:40px;
}

  .mat-header-row::ng-deep {
    background-color: black;
}

.mdc-data-table__header-cell::ng-deep{
  background: #5F5753;
}

.mat-mdc-paginator-container{
  margin-bottom:15px;
}


.custom-sort-header {
  position: relative;
}

 .custom-sort-header .mat-sort-header-arrow {
  display: none; /* Hides the default arrow */
}

.custom-sort-header.mat-sort-header-sorted .mat-sort-header-arrow {
  display: none; /* Ensures it's hidden when sorting */
}

.custom-sort-header::after {
  content: url('../icons/filter_list.svg');
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-sort-header.mat-sort-header-sorted::after {
  content: url('../icons/filter_alt.svg');
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}


  // Secrch box for table

  .search-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:40px 0;
    padding:0 20px;
    border-radius: 4px;
    border: 1px solid var(--Brand-UPS-Grey-1, #5F5753);
    background: var(--UPS-Brand-Colors-UPS-Grey-5, #F2F1EF);
    
    .search-input {
      display: flex;
      align-items: center;
      
      input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-right: 5px;
      }


      .btn{
        margin:0;
        padding:0;
      }
      
      button {
        padding: 8px 20px;
        margin-left:10px;

      }
    }
    
    .filters {

    span{
        padding: 8px 18px;
        margin-left:10px;
    }

      button {
        padding: 8px 12px;
        margin-left: 10px;
        background-color: #28a745; // Green color for Advanced Filter
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
  
        &.download-data {
          background-color: #dc3545; // Red color for Download Data
        }
      }
    }
  }

  input[type="checkbox"]:checked {
    background-color: #a77e2d; /* Change background color when checked */
    color: #ccff00; /* Change color of the check mark */
  }

 .wide-check-box:checked {
    background-color: #a77e2d; /* Change background color when checked */
    color: #ccff00; /* Change color of the check mark */
  }

  .dw-header-cell {
    background-color: #5F5753 !important; /* Your desired background color */
    color: white !important; /* Optional: set text color to white for better contrast */
  }
  
  /* Ensure that you handle the specific needs for Angular Material */
  .mat-header-cell {
    &.dw-header-cell {
      background-color: #5F5753 !important; /* Your desired background color */
      color: white !important; /* Optional: set text color to white for better contrast */
    }
  }