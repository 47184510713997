.sidebar {
    width: 335px;
    background-color: #f0f0f0;
    padding: 20px;
  
    nav ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
  
      li {
        margin-bottom: 10px;
  
        a {
          text-decoration: none;
          color: #333;
          display: block;
          padding: 5px 10px;
  
          &:hover {
            background-color: #ddd;
          }
        }
  
        ul {
          padding-left: 20px;
          display: none; // Initially hide the submenu
        }
  
        &.active > ul {
          display: block; // Show the submenu when the parent li has the 'active' class
        }

        ul.active {
            display: block;
          }
      }
    }
  }