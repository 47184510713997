
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '../src/assets/css/config.scss';
@import '../src/assets//css//ups-theme.scss';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


// styles.scss
$DigitalWalletApp-primary: mat.define-palette($mat-ups-primary);
$DigitalWalletApp-accent: mat.define-palette($mat-ups-secondary, A200, A100, A400);


// The warn palette is optional (defaults to red).
$DigitalWalletApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$DigitalWalletApp-theme: mat.define-light-theme((
  color: (
    primary: $DigitalWalletApp-primary,
    accent: $DigitalWalletApp-accent,
    warn: $DigitalWalletApp-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($DigitalWalletApp-theme);

/* You can add global styles to this file, and also import other style files */

html, body {
  min-height: 100%; // Fixes bug where pop-ups cause white screen in the background
  // height: 100%;
}
body { margin: 0; font-family: 'Roboto', "Helvetica Neue", sans-serif; }

$font-family-ups: "Roboto", sans-serif;

//Allows mat dialog popups to be scrollable without the ugly scroll bar
.cdk-global-overlay-wrapper {
  overflow: auto;
}

// Pop-up/matdialog styles
.popup-global-layout {
  .global-title {
    font-size: 40px;
    font-family: 'roboto';
    color: black;
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    line-height: 1.3;
    margin-right: 50px;
  }

  .global-subtitle {
    font-size: 28px;
    font-family: 'roboto';
    color: black;
    margin-bottom: 8px;
  }

  .global-yellow-bar {
    height: 4px;
    width: 72px;
    background-color: #ffb500;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .global-text {
    color: black;
    font-size: 24px;
    font-family: 'roboto';
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    line-height: 1.3;
  }

  .global-button-format {
    margin-top: 2rem;
    display: flex;
    gap: 10px;
  }

  mat-icon.icon {
    width: 35px;
    height: 35px;
    font-size: 35px;

    @include media-breakpoint-up(md) {
      width: 50px;
      height: 50px;
      font-size: 50px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  // Specific styles
  &.width-650 {
    border-radius: 2px;
    padding: 10px;
    max-width: 650px;

    @include media-breakpoint-up(md) {
      padding: 15px;
    }
  }

  &.width-800 {
    border-radius: 2px;
    padding: 10px;
    max-width: 800px;

    @include media-breakpoint-up(md) {
      padding: 15px;
    }
  }
}

// Global button styles
.dw-primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC400;
  border: 2px solid #FFC400;
  border-radius: 23px;
  color: black;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto';
  min-width: 100px;
  min-height: 42px;
  padding: 0 20px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    color: #AFB1BF;
    background-color: #EFEFF2;
    border: 2px solid #757575;
  }

  &:focus {
    cursor: auto;
  }

  // These classes are for if the mat-icon chevron is to the left or right of the button text
  &.icon-left {
    padding-left: 12px;
  }

  &.icon-right {
    padding-right: 12px;
  }
  
  .spinner-border {
    width: 20px;
    height: 20px;
    color: #E2E2E2;
    margin-left: .25rem;
    border-right-color: #FFC400 !important;
  }

  mat-icon {
    color: black;
  }
}

.dw-secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid #0662BB;
  border-radius: 23px;
  color: #0662BB;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Roboto';
  min-width: 100px;
  min-height: 42px;
  padding: 0 20px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    color: #AFB1BF;
    background-color: #EFEFF2;
    border: 2px solid #AFB1BF;
  }

  &:focus {
    cursor: auto;
  }

  // These classes are for if the mat-icon chevron is to the left or right of the button text
  &.icon-left {
    padding-left: 12px;
  }

  &.icon-right {
    padding-right: 12px;
  }

  mat-icon {
    color: #0662BB;
  }
}

mat-icon.black {
  color: black;
}

mat-icon.blue {
  color: #0662BB;
}

// Global yellow bar
.global-yellow-bar {
  height: 4px;
  width: 72px;
  background-color: #ffb500;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.mdc-checkbox .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background{
  top:0;
  left:0;
  margin:0;
  padding:0;
}

// Mat form field custom CSS for one time payment page input fields
.one-time-payment-wrapper {
  .mat-mdc-form-field {
    margin-top: 16px;
    width: 150px;

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .mat-mdc-form-field-infix {
      min-height: 24px;
    }  

    .mdc-line-ripple::before {
      border-bottom-width: 2px;
    }

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
      padding: 0;

      .mat-mdc-form-field-error {
        margin-top: -12px;
      }
  
      .mat-mdc-form-field-hint {
        margin-top: -12px;
        margin-left: auto;
      }
  
      .mat-mdc-form-field-hint-spacer {
        flex: 0;
      }
    }
  }
}
.pay-div{
  display: flex;
padding: 9.387px 6.258px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 15.644px;
  border-radius: 3.129px;
border: 1px solid var(--UPS-Brand-Colors-UPS-Grey-1, #5F5753);
background: var(--UPS-Brand-Colors-UPS-White, #FFF);
}
.pay-icon{
  display: flex;
padding: 6.286px;
flex-direction: column;
align-items: flex-start;
border-radius: 3.143px;
//border: 1px solid var(--UPS-Brand-Colors-UPS-Grey-1, #5F5753);
background: var(--UPS-Brand-Colors-UPS-White, #FFF);
}

.delete-icon{
  width: 24px  !important;
height: 24px !important;
flex-shrink: 0;
}
.style_italic{
  font-style:italic;
}

.overlay-menu{
//  opacity: 0.5 !important;
  background-color:rgba(0, 0, 0, 0.5) !important;
}

.mat-mdc-radio-button.mat-accent {
  --mat-mdc-radio-ripple-color: none;
  --mat-mdc-radio-checked-ripple-color: none;
}

